import styled, { keyframes } from 'styled-components';
import { SwipeableDrawer, SwipeableDrawerProps } from '@mui/material';

import { collapsedNavSidebarWidth, navSidebarWidth } from '/common/NavSidebar/styles';

import {
  DEFAULT_TOGGLER_BUTTON_OFFSET_PX,
  SIDEBAR_PADDING_DEFAULT_PX,
  SIDEBAR_ROUND_ICON_BUTTON_PADDING_PX,
  SIDEBAR_TRANSITION_CONFIG_DEFAULT,
  SIDEBAR_Z_INDEX,
} from './constants';
import { IGetPseudoTogglingAnimationParams, IStyledDrawerProps, SidebarSide } from './types';
import { getCallbackResultDependingOnSidebarSide } from './helpers';

export const sidebarClasses = {
  sidebarOpen: 'sidebar-open',
  drawerPaper: 'drawer-paper',
  pseudoToggleAnimating: 'pseudo-toggle-animating',
  defaultToggler: 'default-toggler',
  defaultTogglerClosed: 'default-toggler-closed',
  defaultTogglerArrowIcon: 'default-toggler-arrow-icon',
};

const getPseudoTogglingDrawerAnimation = ({
  elementType,
  currentSidebarSide,
  navSidebarSide,
  isNavSidebarPermanentlyOpen,
}: IGetPseudoTogglingAnimationParams) => {
  let initialOffset = 0;
  const offsetQuotient = elementType === 'drawer' ? 1 : -1;

  if (currentSidebarSide === navSidebarSide) {
    initialOffset = isNavSidebarPermanentlyOpen ? navSidebarWidth : collapsedNavSidebarWidth;
  }

  return getCallbackResultDependingOnSidebarSide(currentSidebarSide, {
    [SidebarSide.Left]: () => {
      return keyframes`
          0% { margin-left: ${offsetQuotient * initialOffset}px; }
          100% { margin-left: ${offsetQuotient * (initialOffset + 10)}px; }
        `;
    },
    [SidebarSide.Right]: () => {
      return keyframes`
          0% { margin-right: ${offsetQuotient * initialOffset}px; }
          100% { margin-right: ${offsetQuotient * (initialOffset + 10)}px; }
        `;
    },
  });
};

export const StyledDrawer = styled(SwipeableDrawer)<SwipeableDrawerProps & IStyledDrawerProps>`
  &.${sidebarClasses.pseudoToggleAnimating} {
    .${sidebarClasses.drawerPaper}, .${sidebarClasses.defaultToggler} {
      animation-fill-mode: forwards;
      animation-duration: 0.25s;
    }

    .${sidebarClasses.drawerPaper} {
      animation-name: ${({ anchor, $navSidebarSide, $isNavSidebarPermanentlyOpen }) =>
        getPseudoTogglingDrawerAnimation({
          elementType: 'drawer',
          currentSidebarSide: anchor as SidebarSide,
          navSidebarSide: $navSidebarSide,
          isNavSidebarPermanentlyOpen: $isNavSidebarPermanentlyOpen,
        })};
    }

    .${sidebarClasses.defaultToggler} {
      animation-name: ${({ anchor, $navSidebarSide, $isNavSidebarPermanentlyOpen }) =>
        getPseudoTogglingDrawerAnimation({
          elementType: 'toggler',
          currentSidebarSide: anchor as SidebarSide,
          navSidebarSide: $navSidebarSide,
          isNavSidebarPermanentlyOpen: $isNavSidebarPermanentlyOpen,
        })};
    }
  }

  .${sidebarClasses.drawerPaper} {
    z-index: ${(props) => (!props.$isToggledManually ? SIDEBAR_Z_INDEX : '')};
    overflow: ${(props) => (!props.$isToggledManually ? 'visible' : '')};
    visibility: ${(props) => (!props.$isToggledManually ? 'visible !important' : '')};
  }

  &.${sidebarClasses.sidebarOpen} {
    .${sidebarClasses.drawerPaper} {
      ${({ anchor, $navSidebarSide }) => {
        if (anchor !== $navSidebarSide) {
          return '';
        }

        return getCallbackResultDependingOnSidebarSide(anchor as SidebarSide, {
          [SidebarSide.Left]: () => `left: ${collapsedNavSidebarWidth}px`,
          [SidebarSide.Right]: () => `right: ${collapsedNavSidebarWidth}px`,
        });
      }};
    }
  }

  .${sidebarClasses.defaultToggler} {
    display: block;
    position: absolute;
    bottom: ${SIDEBAR_PADDING_DEFAULT_PX}px;
    padding: ${SIDEBAR_ROUND_ICON_BUTTON_PADDING_PX}px;
    border-radius: 100%;
    width: 0;
    height: 0;
    opacity: 0.5;
    transition: opacity ${SIDEBAR_TRANSITION_CONFIG_DEFAULT}, left ${SIDEBAR_TRANSITION_CONFIG_DEFAULT};
    ${({ anchor, open, $navSidebarSide, $isNavSidebarPermanentlyOpen }) => {
      let defaulTogglerButtonOffsetAdditional = 0;

      if (anchor === $navSidebarSide && !open) {
        defaulTogglerButtonOffsetAdditional = $isNavSidebarPermanentlyOpen ? navSidebarWidth : collapsedNavSidebarWidth;
      }

      return getCallbackResultDependingOnSidebarSide(anchor as SidebarSide, {
        [SidebarSide.Left]: () => `
          left: calc(100% + ${DEFAULT_TOGGLER_BUTTON_OFFSET_PX + defaulTogglerButtonOffsetAdditional}px);
          transform: translateY(-10%) rotate(180deg);
        `,
        [SidebarSide.Right]: () => `
          right: calc(100% + ${DEFAULT_TOGGLER_BUTTON_OFFSET_PX + defaulTogglerButtonOffsetAdditional}px);
          transform: translateY(-10%);
        `,
      });
    }};

    &:hover {
      opacity: 1;
    }

    &.${sidebarClasses.defaultTogglerClosed} {
      ${(props) =>
        getCallbackResultDependingOnSidebarSide(props.anchor as SidebarSide, {
          [SidebarSide.Left]: () => 'transform: translateY(-10%);',
          [SidebarSide.Right]: () => 'transform: translateY(-10%) rotate(180deg);',
        })};
    }
  }

  .${sidebarClasses.defaultTogglerArrowIcon} {
    font-size: 1.3rem;
    position: absolute;
    left: 50%;
    top: 50%;

    &:first-of-type {
      transform: translate(-35%, -50%);
    }

    &:last-of-type {
      transform: translate(-70%, -50%);
    }
  }
`;
